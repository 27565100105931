import React from "react";
import LoginForm from "./login_page";
import { Layout, Spin, Modal, notification, Tooltip, Button, Icon,Radio } from "antd";
const { confirm } = Modal;
const { Content, Sider } = Layout;
import { login, monitorupdate } from "./api/categories";
import MeetingPage from "./meeting_page";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import VideoIcon from "mdi-react/VideoIcon";
import VideoOffIcon from "mdi-react/VideoOffIcon";
import TelevisionIcon from "mdi-react/TelevisionIcon";
import TelevisionOffIcon from "mdi-react/TelevisionOffIcon";
import BrushOffIcon from "mdi-react/BrushIcon";
import BrushIcon from "mdi-react/BrushIcon";
import BroomIcon from "mdi-react/BroomIcon";
import HangupIcon from "mdi-react/PhoneHangupIcon";
import FullscreenIcon from "mdi-react/FullscreenIcon";
import FullscreenExitIcon from "mdi-react/FullscreenExitIcon";
import CheckboxIntermediateIcon from "mdi-react/CheckboxIntermediateIcon";
import CheckboxBlankOutlineIcon from "mdi-react/CheckboxBlankOutlineIcon";
import ShareMeeting from "./share_meeting";
import PenMeeting from "./pen_meeting";

import MediaSettings from "./setting";
import { v4 as uuidv4 } from "uuid";
import { Client } from "ion-sdk-js";
//import { IonSFUGRPCWebSignal } from 'ion-sdk-js/lib/signal/grpc-web-impl';
import { IonSFUJSONRPCSignal } from "ion-sdk-js/lib/signal/json-rpc-impl";
import { reactLocalStorage } from "reactjs-localstorage";
import UserList from "./user";
import ChatFeed from "./chat";
import Message from "./chat/message";
import "../styles/css/app.scss";
import { ZhihuCircleFilled } from "@ant-design/icons/lib/icons";
const MyContext = React.createContext();
class Samples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  streams: [],
      login: true,
      loading: false,
      collapsed: false,
      loginInfo: {},
      uid: "",
      roomId: "",
      name: "",
      users: [],
      messages: [],
      localAudioEnabled: true,
      localVideoEnabled: true,
      localVideoEnabledBig: sessionStorage.getItem('bigvideo')==0?false:true,
      screenSharingEnabled: false,
      vidFit: false,
      brush: false,
      isFullScreen1: false,
      isFullScreen: false,
	   selectedOption: false,
    };

    this.settings = {
      selectedAudioDevice: "",
      selectedVideoDevice: "",
	  selectedVideoDeviceLocal: "",
      resolution: "hd",
      bandwidth: 1024,
      codec: "H264",
    };
	
    let settings = reactLocalStorage.getObject("settings");
    if (settings.codec !== undefined) {
      this.settings = settings;
    }
	
  }
  UNSAFE_componentWillMount() {
	  
	  let roomId1=this.GetRequest2('room')
	  if(roomId1!=""){
		  this.handleLogin({
		    roomId: roomId1,
		    displayName: sessionStorage.getItem("userId")
		      ? sessionStorage.getItem("userId")
		      : "admin",
		  });
	  }else{
		  if(sessionStorage.getItem('roomId')!=""&&sessionStorage.getItem('roomId')!=null){
		  	this.handleLogin({
		  	  roomId: sessionStorage.getItem('roomId'),
		  	  displayName: sessionStorage.getItem("userId")
		  	    ? sessionStorage.getItem("userId")
		  	    : "admin",
		  	});
		  }else {
		  	this.setState({ login: false });
		  	window.location.href = "https://zeiss.local.xytechsz.com/";
		  	history.go(0)
		  }
		  
	  }
    
  }
   GetRequest2(key){
      var url = window.location.hash;  
      var para = url; /* 获取属性（“?”后面的分段） */
      para=para.toString().substring(15);
     
      return para;
   }
  handleLogin = async (values) => {
    let uid = uuidv4();
    this.setState(
      {
        login: true,
        loading: false,
        uid: uid,
        roomId: values.roomId,
        name: values.displayName,
      },
      () => {
        // var url = "wss://xytechsz.virgilboss.top/meeting_ws";
        var url = "wss://auser.xytechsz.com/meeting_ws";
		// var url = "wss://127.0.0.1:8001/ws";
		
		
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
          console.log("WebSocket连接成功....");
          let message = {
            type: "joinRoom",
            data: {
              name: this.state.name,
              id: uid,
              roomId: values.roomId,
            },
          };
          this.send(message);
        };

        this.socket.onmessage = (e) => {
          var message = JSON.parse(e.data);
          // console.log(
          //   "收到的消息:{\n type= " +
          //     message.type +
          //     ", \n data =  " +
          //     JSON.stringify(message.data) +
          //     "\n"
          // );
			
          switch (message.type) {
            case "userJoin":
              break;
            case "leaveRoom":
              break;
            case "publicChat":
              if (message.data.text == "drawLine2") {
                if(message.data.name== sessionStorage.getItem("userName")&&message.data.token!= sessionStorage.getItem("token")){
              					this.removeCookie("logostatelocal");
              					 sessionStorage.clear();
                     window.location.href = "https://zeiss.local.xytechsz.com/"; 
                }
                
              } else{
              				  if (JSON.parse(message.data.text).type == "drawLine") {
              				    if(message.data.name!=sessionStorage.getItem("userName")){
              				        this.meeting.bigVideo.drawCanvas(
              				            message.data.text,
              				            message.data.colorType,
              				            message.data.penZize,
              				           
              				          );
              				    }
              				    
              				  } else if (JSON.parse(message.data.text).type == "clearCanvas") {
              				    this.meeting.bigVideo.clearCanvas2();
              				  }else if(JSON.parse(message.data.text).type == "show"){
								   sessionStorage.setItem(JSON.parse(JSON.parse(message.data.text).data).streamid,JSON.parse(JSON.parse(message.data.text).data).userName);
								   
								}
								else if(JSON.parse(message.data.text).type == "mainshow"){
										
										this.meeting.onChangeVideoPositionSam(JSON.parse(JSON.parse(message.data.text).data).mainId)
										
										if(JSON.parse(JSON.parse(message.data.text).data).userName!=sessionStorage.getItem("userName")){
											// this.state.selectedOption=false
											this.setState({
											  selectedOption: false,
											});
											try {
												this.meeting.bigVideo.handlehide()
											} catch (error) {
												
											}
											
											try {
												this.meeting.localVideo.handlehide()
											} catch (error) {
												
											}
													
													
													try {
														this.meeting.handlehide()
													} catch (error) {
														
													}
											this.setState({ isFullScreen1: true });
										}
											// console.log(JSON.parse(JSON.parse(message.data.text).data).mainId)					   
								 // {"colorType":null,"id":"4477f75f-61ca-4643-ac81-da6d2c3c9667","name":"a2","penZize":null,"roomId":"80014","text":"{\"type\":\"mainshow\",\"data\":{\"userName\":\"a2\",\"mainId\":\"ee255ad6-2256-4a4c-bd32-19411eb89e87\"}}"}
								
								
								}
              }
              //判断是否为清除 ，如果是清除
              // this.meeting.bigVideo.clearCanvas()
              // 如果不是清除就画点
              // this.meeting.bigVideo.drawCanvas()
              break;
            case "heartPackage":
              console.log('服务端发心跳包!');
              break;
            case "updateUserList":
              let users = message.data;
              this.setState({
                users: users,
              });
              break;
          }
        };

        this.socket.onerror = (e) => {
          console.log("onerror::" + e.data);
        };

        this.socket.onclose = (e) => {
          console.log("onclose::" + e.data);
        };

        const signal = new IonSFUJSONRPCSignal(
          // "wss://xytechsz.virgilboss.top/meeting_sfu"
          // "wss://whost.xytechsz.com/meeting_sfu"
		  "wss://auser.xytechsz.com/meeting_sfu"
        );
        const client = new Client(signal);
        this.client = client;
        signal.onopen = () => {
          client.join(this.state.roomId, this.state.userName);
          this.onJoin(values, uid);
        };
      }
    );
  };

  onJoin = async (values, uid) => {
    reactLocalStorage.remove("loginInfo");
    reactLocalStorage.setObject("loginInfo", values);
    this.setState(
      {
        login: true,
        loading: false,
        uid: uid,
        loginInfo: values,
      },
      () => {
        this.meeting.publishLocalStream ();
        // this.showMessage("已连接!", "欢迎加入会议 =>" + values.roomId);
		this.onSendUserMessage("drawLine2");
		
      }
    );
  };
  showMain(){
	  if(sessionStorage.getItem("streamsobj1")){
		  this.onSendMessage(JSON.stringify({
	  type:"show",
	  data:sessionStorage.getItem("streamsobj1")
	  }))}
	  if(sessionStorage.getItem("ShareScreen")){
	  		  this.onSendMessage(JSON.stringify({
	  type:"show",
	  data:sessionStorage.getItem("ShareScreen")
	  }))}
	  if(sessionStorage.getItem("streamsobj2")){
	  		  this.onSendMessage(JSON.stringify({
	  type:"show",
	  data:sessionStorage.getItem("streamsobj2")
	  }))}
	  if(this.state.selectedOption==true&&this.meeting.state.streams[0].id){
	  	this.onSendMessage(JSON.stringify({
	  	type:"mainshow",
	  	data:JSON.stringify({
			userName:sessionStorage.getItem("userName"),
			mainId:this.meeting.state.streams[0].id
		})
		//显示userName  发送中间id  主持人
	  	}))
	  }
	  
	  
	  
  }
  
	changeFullScreenClickHandler2 = async () => {
		
		 this.onFullScreenClickHandler2()
	};
  
  componentDidMount(){
	  this.changeFullScreenClickHandler2()
          this.timerID = setInterval(
              () => this.showMain(),
              1000
          );
      }
  
      componentWillUnmount(){
          clearInterval(this.timerID)
      }
	onSendUserMessage = (msg) => {
	  let message = {
	    type: "publicChat",
	    data: {
	      name: this.state.name,
	      id: this.state.uid,
	      roomId: this.state.roomId,
	      text: msg,
	      token: sessionStorage.getItem("token"),
	     
	    },
	  };
	  this.send(message);
	};
  onSendMessage = (msg) => {
    let message = {
      type: "publicChat",
      data: {
		  
        name: sessionStorage.getItem("userName"),
        id: this.state.uid,
        roomId: this.state.roomId,
        text: msg,
        colorType: sessionStorage.getItem("color"),
        penZize: sessionStorage.getItem("pensize"),
      },
    };
    this.send(message);
  };

  onSystemMessage = (msg) => {
    let messages = this.state.messages;
    let uid = 2;
    messages.push(new Message({ id: uid, message: msg, senderName: "System" }));
    this.setState({ messages });
  };

  showMessage = (message, description) => {
    notification.info({
      message: message,
      description: description,
      placement: "topRight",
    });
  };

  send = (data) => {
    this.socket.send(JSON.stringify(data));
  };
  showManege() {
    window.location.href = "https://amanage.xytechsz.com/";
  }
  handleAudioTrackEnabled = (enabled) => {
    this.setState({
      localAudioEnabled: enabled,
    });
    this.meeting.muteMediaTrack("audio", enabled);
  };

  handleVideoTrackEnabled = (enabled) => {
    this.setState({
      localVideoEnabled: enabled,
    });
    this.meeting.muteMediaTrack("video", enabled);
  };
	
	handleVideoTrackEnabledBig = (enabled) => {
    console.log(enabled)
    if(enabled==true){
      sessionStorage.setItem("bigvideo",1);
    }else{
      sessionStorage.setItem("bigvideo",0);

    }
   
	  this.setState({
	    localVideoEnabledBig: enabled,
	  });
    history.go(0)
	  // this.meeting.muteMediaTrackBig("video", enabled);
	};
  handleScreenSharing = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
      // localVideoEnabled:!enabled
    });
    this.meeting.handleScreenSharing(enabled);
    // this.meeting.muteMediaTrack("video", !enabled);
  };

  onScreenSharingCallBack = (enabled) => {
    this.setState({
      screenSharingEnabled: enabled,
    });
  };

  openOrCloseLeftContainer = (collapsed) => {
    this.setState({
      collapsed: collapsed,
    });
  };

  cleanUp = async () => {
	  
    await this.meeting.cleanUp();
  };
  setCookie = (name, value, iDay) => {
    var oDate = new Date();

    oDate.setDate(oDate.getDate() + iDay);

    document.cookie = name + "=" + value + ";expires=" + oDate;
  };
  getCookie = (name) => {
    var arr = document.cookie.split("; ");
    var i = 0;
    for (i = 0; i < arr.length; i++) {
      var arr2 = arr[i].split("=");

      if (arr2[0] == name) {
        return arr2[1];
      }
    }
    return ""; //如果没有就返回空值
  };
  removeCookie = (name) => {
    this.setCookie(name, 0, -1); //将过期时间设置为昨天，系统就自动删除了
  };
  userMonitor = async () => {
    this.removeCookie("logostatelocal");
    let monitorId = sessionStorage.getItem("monitorId");
    console.log(monitorId);
    if (monitorId) {
      console.log(monitorId);
      const res = await monitorupdate({
        id: monitorId,
      });
      console.log(res);
    }
  };
  
    getDifValue(nowtimestamp, beforetimestamp){
              var difValue = nowtimestamp - beforetimestamp;
			 
              var day = Math.floor(difValue / 1000 / 60 / 60 / 24);//天
              difValue = difValue % (1000 * 60 * 60 * 24);
              var hour = Math.floor(difValue / 1000 / 60 / 60);//小时
              difValue = difValue % (1000 * 60 * 60);
              var min = Math.floor(difValue / 1000 / 60);//分钟
              difValue = difValue % (1000 * 60);
              var second = Math.floor(difValue / 1000);
			  var money=difValue/(1000*60)*0.3
			   return "用时:" + day + "年" + hour + "小时" + min + "分钟" + second + "秒"+"   "+"产生了"+money.toFixed()+"元"
          }
         

  hangUp = async () => {
    const that = this;
    this.userMonitor();
    confirm({
      title: "退出？",
      content: this.getDifValue(new Date().getTime(),sessionStorage.getItem("toMeetingTime")) + "  是否退出会议?",
      okText: "确定",
      cancelText: "取消",
      async onOk() {
        await that.cleanUp();
        that.setState({ login: false });
		window.location.href = "https://zeiss.local.xytechsz.com/#/RoomList";
        // window.location.href = "https://localhost:8080/#/RoomList";
		history.go(0)
        // window.location.href = "https://alocal.xytechsz.com#/RoomList";
      },
      onCancel() {},
    });
  };

  onVideodFitClickHandler = () => {
    this.setState({
      vidFit: !this.state.vidFit,
    });
  };
  onBrushClickHandler = () => {
    this.setState({
      brush: !this.state.brush,
    });
    this.meeting.bigVideo.setDrawState();
  };

  onBroomClickHandler = () => {
  
    this.meeting.bigVideo.clearCanvas();
  };
  onFullScreenClickHandler = () => {
    let docElm = document.documentElement;
	
    if (this.state.isFullScreen1) {
		try {
			this.meeting.bigVideo.handleshow()
		} catch (error) {
			
		}
		
		
		try {
			this.meeting.localVideo.handleshow()
		} catch (error) {
			
		}
		
		try {
				this.meeting.handleshow()
		} catch (error) {
			
		}
		
  //     if (document.exitFullscreen) {
		//   console.log(2)
		// this.meeting.bigVideo.handleshow()
  //     } 

      this.setState({ isFullScreen1: false });
    } else {
		try {
			this.meeting.bigVideo.handlehide()
		} catch (error) {
			
		}
		
		try {
			this.meeting.localVideo.handlehide()
		} catch (error) {
			
		}
		try {
				this.meeting.handlehide()
		} catch (error) {
			
		}
	
     
      // this.setState({ isFullScreen: true });
      this.setState({ isFullScreen1: true });
    }
  };
	onFullScreenClickHandler2 = () => {
	  let docElm = document.documentElement;
		
	  if (this.fullscreenState()) {
			// this.meeting.bigVideo.handleshow()
			// this.meeting.localVideo.handleshow()
			// this.meeting.handleshow()
			console.log(1)
	    if (document.exitFullscreen) {
			  console.log(2)
			// this.meeting.bigVideo.handleshow()
	      document.exitFullscreen();
	    } else if (document.mozCancelFullScreen) {
			   console.log(3)
	      document.mozCancelFullScreen();
	    } else if (document.webkitCancelFullScreen) {
	      document.webkitCancelFullScreen();
	    } else if (document.msExitFullscreen) {
	      document.msExitFullscreen();
	    }
	
	    this.setState({ isFullScreen: false });
	  } else {
			// this.meeting.bigVideo.handlehide()
			// this.meeting.localVideo.handlehide()
			// this.meeting.handlehide()
	    if (docElm.requestFullscreen) {
			 
			try {
				docElm.requestFullscreen();
			} catch (error) {
				
			}
	      
		  
	    }
	    //FireFox
	    else if (docElm.mozRequestFullScreen) {
	      docElm.mozRequestFullScreen();
	    }
	    //Chrome等
	    else if (docElm.webkitRequestFullScreen) {
			    console.log(10120)
	      docElm.webkitRequestFullScreen();
	    }
	    //IE11
	    else if (elem.msRequestFullscreen) {
	      elem.msRequestFullscreen();
	    }
			
	    this.setState({ isFullScreen: true });
	  }
	};
  fullscreenState = () => {
    return (
      document.fullscreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      false
    );
  };
	handleCancelSelection = (e) => {
	
	   this.setState({
	      selectedOption: !this.state.selectedOption
	    });
		
	}
	handleChange = (e) => {
	 
	   
	}
  onMediaSettingsChanged = (
    selectedAudioDevice,
    selectedVideoDevice,
    selectedVideoDeviceLocal,
    resolution,
    bandwidth,
    codec
  ) => {
    this.settings = {
      selectedAudioDevice,
      selectedVideoDevice,
	  selectedVideoDeviceLocal,
      resolution,
      bandwidth,
      codec,
    };
    reactLocalStorage.setObject("settings", this.settings);
  };

  render() {
	  
    const {
      login,
      loading,
      localAudioEnabled,
      localVideoEnabled,
      localVideoEnabledBig,
      screenSharingEnabled,
      collapsed,
      vidFit,
    } = this.state;
    return (
      <Layout className="app-layout">
        {login ? (
          <Content className="app-center-layout">
            <Layout className="app-content-layout">
              <Layout className="app-right-layout">
                <Content style={{ flex: 1 }}>
                 <MyContext.Provider array={this.state.streams}>
                           <MeetingPage
                             onSendMessage={this.onSendMessage}
                             uid={this.state.uid}
                             collapsed={this.state.collapsed}
                             users={this.state.users}
                             settings={this.settings}
                             client={this.client}
                             localAudioEnabled={localAudioEnabled}
                             localVideoEnabled={localVideoEnabled}
                             localVideoEnabledBig={localVideoEnabledBig}
                             screenSharingCallBack={this.onScreenSharingCallBack}
                           					onMediaSettingsChanged={this.onMediaSettingsChanged}
                             vidFit={vidFit}
                             ref={(ref) => {
                               this.meeting = ref;
                             }}
                           />
                 </MyContext.Provider>
				  
                  

                  <div className="app-bottom-tools-bg"></div>

                  <div className="app-bottom-tools">
                    <Tooltip title="打开关闭面板"></Tooltip>

                    <div className="app-bottom-tool">
						
					  <Radio  onClick={this.handleCancelSelection} value="option1" checked={this.state.selectedOption ==true} onChange={this.handleChange}><span style={{color:"#ffffff",}}>主持人</span></Radio>
                      <Tooltip title="禁音/取消">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          style={{ color: localAudioEnabled ? "" : "red" }}
                          onClick={() =>
                            this.handleAudioTrackEnabled(!localAudioEnabled)
                          }
                        >
                          <Icon
                            component={
                              localAudioEnabled
                                ? MicrophoneIcon
                                : MicrophoneOffIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />

                          <p>禁音/取消</p>
                        </Button>
                      </Tooltip>

                      <Tooltip title="打开/关闭视频1">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          style={{ color: localVideoEnabled ? "" : "red" }}
                          onClick={() =>
                            this.handleVideoTrackEnabled(!localVideoEnabled)
                          }
                        >
                          <Icon
                            component={
                              localVideoEnabled ? VideoIcon : VideoOffIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>打开/关闭视频1</p>
                        </Button>
                      </Tooltip>
						<Tooltip title="打开/关闭视频2">
						  <Button
						    size="large"
						    type="link"
						    ghost
						    style={{ color: localVideoEnabledBig ? "" : "red" }}
						    onClick={() =>
						      this.handleVideoTrackEnabledBig(!localVideoEnabledBig)
						    }
						  >
						    <Icon
						      component={
						        localVideoEnabledBig ? VideoIcon : VideoOffIcon
						      }
						      style={{
						        display: "flex",
						        justifyContent: "center",
						      }}
						    />
						    <p>打开/关闭视频2</p>
						  </Button>
						</Tooltip>
                      <Tooltip title="共享桌面">
                        <Button
                          ghost
                          size="large"
                          type="link"
                          style={{ color: screenSharingEnabled ? "red" : "" }}
                          onClick={() =>
                            this.handleScreenSharing(!screenSharingEnabled)
                          }
                        >
                          <Icon
                            component={
                              screenSharingEnabled
                                ? TelevisionOffIcon
                                : TelevisionIcon
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>共享桌面</p>
                        </Button>
                      </Tooltip>

                      <Tooltip title="挂断">
                        <Button
                          shape="circle"
                          size="large"
                          type="danger"
                          ghost
                          style={{ marginLeft: 16, marginRight: 16 }}
                          onClick={() => this.hangUp()}
                        >
                          <Icon
                            component={HangupIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Button>
                      </Tooltip>
                      <ShareMeeting loginInfo={this.state.loginInfo} />
                      <MediaSettings
                        onMediaSettingsChanged={this.onMediaSettingsChanged}
                        settings={this.settings}
                      />
                      <PenMeeting
                        pen={this.state.pen}
                        changeColor={this.changeColor}
                        ref={(ref) => {
                          this.penType = ref;
                        }}
                      />

                      <Tooltip title="清除">
                        <Button
                          size="large"
                          type="link"
                          ghost
                          onClick={() => this.onBroomClickHandler()}
                        >
                          <Icon
                            component={BroomIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                          <p>清除</p>
                        </Button>
                      </Tooltip>
                    </div>
					
                    <Tooltip title="全屏/取消1">
                      <Button
                        ghost
                        size="large"
                        type="link"
                        onClick={() => this.onFullScreenClickHandler()}
                      >
                        <Icon
                          component={
                            this.state.isFullScreen
                              ? FullscreenExitIcon
                              : FullscreenIcon
                          }
                          style={{ display: "flex", justifyContent: "center" }}
                        />
                        <p>全屏/取消1</p>
                      </Button>
                    </Tooltip>
					<Tooltip title="全屏/取消2">
					  <Button
					    ghost
					    size="large"
					    type="link"
					    onClick={() => this.onFullScreenClickHandler2()}
					  >
					    <Icon
					      component={
					        this.state.isFullScreen
					          ? FullscreenExitIcon
					          : FullscreenIcon
					      }
					     
					    />
					    <p>2全屏/取消</p>
					  </Button>
					</Tooltip>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Content>
        ) : loading ? (
          <Spin size="large" tip="正在连接..." />
        ) : (
          <LoginForm handleLogin={this.handleLogin} />
        )}
      </Layout>
    );
  }
}

export default Samples;

 
 
 
